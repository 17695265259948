import React from 'react'
import SliceManager from 'components/shared/SliceManager'
import { getDataDependencies } from 'services/api'
import fetchSingleType from 'src/lib/Strapi/fetchSingleType'
import PreviewBanner from 'components/shared/PreviewBanner'
import delve from 'dlv'
const LaunchWeek = ({ pageData, preview }) => {
  const slices = delve(pageData, 'slices')
  return (
    <div
      style={{ fontFamily: 'var(--font-inter)', backgroundColor: '#00031C' }}
    >
      {preview && <PreviewBanner />}
      {slices && <SliceManager slices={slices} />}
    </div>
  )
}

export async function getStaticProps({ preview = null }) {
  const data = await fetchSingleType('launch-week', preview)
  const pageData = await getDataDependencies(data)
  return {
    props: {
      pageData,
      preview,
    },
  }
}
export default LaunchWeek
